<template>
    <CompTable ref="comp_table" title="栏目更新提醒设置" :columns="columns" :table-api="tableApi">
        <template #search="evt">
            <Select v-model="evt.search.dimensionId" placeholder="机构类型">
                <Option v-for="item in dimensionList" :key="item.id" :value="item.id">{{ item.name }}</Option>
            </Select>
            <Input v-model="evt.search.orgName" placeholder="机构名称"></Input>
        </template>
    </CompTable>
</template>

<script>
import CompTable from "@/views/jointly/components/comp-table.vue"
import Route from "@/views/communitymobilization/entity/Route"

export default {
    components: {
        CompTable,
    },

    data() {
        return {
            tableApi: `/gateway/api/symanage/pc/columnAlarm/listAllByPage`,
            columns: [
                {
                    title: "机构名称",
                    key: "orgName",
                },
                {
                    title: "服务范围",
                    key: "communityName",
                },
                {
                    title: "包含示范社区",
                    key: "communityName",
                },
                {
                    title: "是否设置",
                    render: (h, params) => {
                        return h("i-switch", {
                            props: {
                                value: params.row.status == 1,
                            },
                            on: {
                                "on-change": v => {
                                    params.row.total = v
                                },
                            },
                        })
                    },
                },
                {
                    title: "操作",
                    fixed: "right",
                    width: 150,
                    render: (h, params) => {
                        return h(
                            "Button",
                            {
                                props: {
                                    type: "info",
                                    // size: "small",
                                },
                                on: {
                                    click: () => {
                                        Route.jump("/columnremindersetting", {
                                            orgName: params.row.orgName,
                                            orgCode: params.row.orgCode,
                                        })
                                    },
                                },
                            },
                            "设置"
                        )
                    },
                },
            ],

            dimensionList: null,
        }
    },

    created() {
        this.$get(
            "/orgzz/pc/dimension/selectAuthorizationDimension",
            {
                appCode: "",
            },
            { "Content-Type": "application/json" }
        ).then(res => {
            this.dimensionList = res.dataList
        })
    },
}
</script>

<style></style>
